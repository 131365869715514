import { useWeb3React } from '@web3-react/core'
import { abi as stakingPoolABI } from 'abis/StakingPool.json'
import axios from 'axios'
import { Interface } from 'ethers/lib/utils'
import { useToken } from 'hooks/Tokens'
import {
  useESAvatrContract,
  useInterfaceMulticall,
  usePiarContract,
  useStakeETHPool,
  useStakePool,
  useTokenContract,
} from 'hooks/useContract'
import { useV2Pair } from 'hooks/useV2Pairs'
import { useMultipleContractSingleData, useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useEffect, useMemo, useState } from 'react'
import { BN, toFromBN } from 'utils/bn'
const POOL_INTERFEACE = new Interface(stakingPoolABI)
const avatr = '0x7b45D0542a62Aa6f4f8ec93EA1D4E62425BA9A1E'
const esavatr = '0x5F5e807C09E31776D51f6ba66c1944a9e8dd4096'

export const useQueryState = (data: any) => {
  const { pool, stakeToken, isLP, isNative } = data
  const { account } = useWeb3React()
  const token = useToken(stakeToken)
  const multicall = useInterfaceMulticall()

  const tokenContact = useTokenContract(isNative ? undefined : stakeToken)

  const poolsETH = useStakeETHPool(pool)
  const pools = useStakePool(pool)
  const { result: ethBalance } = useSingleCallResult(multicall, 'getEthBalance', [account ?? undefined])

  const { result: tokenBalance } = useSingleCallResult(tokenContact, 'balanceOf', [account ?? undefined])

  const { result: earneds } = useSingleCallResult(pools, 'earned', [account ?? undefined])

  const { result: totalSupplys } = useSingleCallResult(pools, 'totalSupply')

  const Balance = useMemo(() => {
    if (!token) return
    if (isNative && ethBalance) {
      return toFromBN(ethBalance[0])
    }
    if (!isNative && tokenBalance) {
      return toFromBN(tokenBalance[0], token.decimals)
    }
    return
  }, [ethBalance, isNative, token, tokenBalance])

  const earned = useMemo(() => {
    if (!earneds) return
    return toFromBN(earneds[0])
  }, [earneds])

  const totalSupply = useMemo(() => {
    if (!totalSupplys) return
    return toFromBN(totalSupplys[0])
  }, [totalSupplys])
  return {
    tokenBalance: Balance,
    earned,
    contract: isNative ? poolsETH : pools,
    totalSupply,
  }
}
export const useAllData = (data: any) => {
  const { account } = useWeb3React()
  const pools = data?.map((item: any) => item.pool)
  const totalSupplys = useMultipleContractSingleData(pools, POOL_INTERFEACE, 'totalSupply')
  const earneds = useMultipleContractSingleData(pools, POOL_INTERFEACE, 'earned', [account ?? undefined])
  const ewardPerTokens = useMultipleContractSingleData(pools, POOL_INTERFEACE, 'rewardPerToken')

  const priceAll: any = useGetPirce()
  const avatLP = useAvatrPair(priceAll['ethereum']?.usd)
  const AgiLP = useAgiPair(priceAll['ethereum']?.usd)
  const dalps = data?.reduce(
    (pre: any, curr: any, index: any) => {
      if (
        !earneds ||
        !totalSupplys ||
        !ewardPerTokens ||
        earneds.length == 0 ||
        totalSupplys.length == 0 ||
        ewardPerTokens.length === 0
      ) {
        pre.lpArr.push(curr)

        return pre
      }

      const { result: totalSupply } = totalSupplys[index]
      const { result: earned } = earneds[index]
      const { result: ewardPerToken } = ewardPerTokens[index]
      if (!totalSupply || !earned || !ewardPerToken) return pre
      const { stakeToken, name, dayOut } = curr

      const price = priceAll[curr.goname]
      curr.price = price?.usd || 1
      if (name === 'AGI-ETH') {
        curr.price = AgiLP || 1
      }
      if (name === 'AVATR-ETH') {
        curr.price = avatLP?.lpPrice || 1
      }

      if (name === 'BLSD-ETH') {
        curr.price = 1
      }

      curr.totalSupply = toFromBN(totalSupply[0])
      curr.totalSupply2 = toFromBN(totalSupply[0]).toFixed()
      curr.earned = toFromBN(earned[0])
      curr.tvl = curr.totalSupply.times(curr.price).toFixed(2)
      curr.tvl1 = curr.totalSupply.times(curr.price || 0).toFixed(2)
      const appirce = BN(avatLP.avatrPirce ?? 0.1)
      const calcApr = BN(dayOut)
        .times(appirce.lte(0) ? 0.1 : appirce)
        .times(365)
        .div(curr.tvl)
        .times(100)
      curr.apr = calcApr.toFixed(2) === 'NaN' || calcApr.toFixed(2) == 'Infinity' ? '9999999' : calcApr.toFixed(2)
      pre.lpArr.push(curr)
      const pulst = BN(curr.tvl).plus(pre.tvl).toFixed(2)
      pre.tvl = pulst === 'NaN' ? pre.tvl : pulst
      return pre
    },
    { lpArr: [], tvl: 0, avatPrice: avatLP?.avatrPirce ?? 0.1 }
  )
  return dalps
}

const useGetPirce = () => {
  const [data, setData] = useState({})

  useEffect(() => {
    let timer: any = null
    function feach() {
      axios
        .get(
          'https://api.coingecko.com/api/v3/simple/price?ids=ethereum,staked-ether,rocket-pool-eth,frax-ether,ankreth&vs_currencies=usd'
        )
        .then((res) => {
          setData(res.data)
        })
    }

    feach()
    timer = setInterval(feach, 100000)
    return () => {
      clearInterval(timer)
    }
  }, [])
  return data
}

const useAvatrPair = (ethPirce?: any) => {
  const tokena = useToken('0xa5d04fD00Ed911Ba809B4D1BC9e90F79eE5A4e47')
  const tokenb = useToken('0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2')
  const [, pair] = useV2Pair(tokena ?? undefined, tokenb ?? undefined)
  const pairContract = usePiarContract('0xFdCF1957F2054327e46FfC5eCe0C30Bc622202F0')
  const { result: totalSupplys } = useSingleCallResult(pairContract, 'totalSupply')

  const lpPrice = useMemo(() => {
    if (!pair || !totalSupplys || !ethPirce) return
    const totalSupply = toFromBN(totalSupplys[0])
    const addd = BN(pair.reserve1.toFixed())
    if (addd.lte(0)) return 0
    const curr = addd.div(totalSupply).times(ethPirce).toFixed()
    return curr
  }, [ethPirce, pair, totalSupplys])
  const avatrPirce = useMemo(() => {
    if (!pair || !ethPirce) return
    const addd = BN(pair.reserve1.toFixed())
    if (addd.lte(0)) return 0
    const curr = BN(ethPirce).div(pair.token1Price.toFixed()).toFixed(4)
    return curr
  }, [ethPirce, pair])
  return { lpPrice, avatrPirce }
}

const useAgiPair = (ethPirce?: any) => {
  const tokena = useToken('0x5F18ea482ad5cc6BC65803817C99f477043DcE85')
  const tokenb = useToken('0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2')
  const [, pair] = useV2Pair(tokena ?? undefined, tokenb ?? undefined)
  const pairContract = usePiarContract('0x498c00E1ccC2AFFf80F6Cc6144EAEB95c46cc3B5')
  const { result: totalSupplys } = useSingleCallResult(pairContract, 'totalSupply')
  return useMemo(() => {
    if (!pair || !totalSupplys || !ethPirce) return
    const totalSupply = toFromBN(totalSupplys[0])
    const addd = BN(pair.reserve1.toFixed())
    const curr = addd.div(totalSupply).times(ethPirce).toFixed()
    return curr
  }, [ethPirce, pair, totalSupplys])
}
export const useAvatr = () => {
  const { account } = useWeb3React()
  const tokenContact = useTokenContract(avatr)
  const tokenContact1 = useESAvatrContract(esavatr)
  const { result: tokenBalance } = useSingleCallResult(tokenContact, 'balanceOf', [account ?? undefined])
  const { result: tokenBalance1 } = useSingleCallResult(tokenContact1, 'balanceOf', [account ?? undefined])
  const Balance = useMemo(() => {
    if (!tokenBalance) return
    return toFromBN(tokenBalance[0])
  }, [tokenBalance])
  const Balance2 = useMemo(() => {
    if (!tokenBalance1) return
    return toFromBN(tokenBalance1[0])
  }, [tokenBalance1])

  return {
    avatr: Balance,
    esavatr: Balance2,
    esavatrContract: tokenContact1,
  }
}

export const useEsAvatrList = () => {
  const tokenContact1 = useESAvatrContract(esavatr)
  const { account } = useWeb3React()
  const { result: redeemsLength } = useSingleCallResult(tokenContact1, 'getUserRedeemsLength', [account ?? undefined])
  const input = useMemo(() => {
    if (!redeemsLength || !account) return []
    const len = redeemsLength[0].toNumber()
    const arry = []
    for (let i = 0; i < len; i++) {
      arry.push([account, i])
    }
    return arry
  }, [account, redeemsLength])
  const redeemList = useSingleContractMultipleData(tokenContact1, 'getUserRedeem', input)

  return redeemList.reduce((pre: any, curr) => {
    if (!curr) return pre

    const { result } = curr
    if (!result) return pre
    pre.push(result)
    return pre
  }, [])
}
export const useESAvatrsContract = () => {
  return useESAvatrContract(esavatr)
}
export const useESAvatrsTotal = () => {
  const add = useESAvatrsContract()
  const { result: totalSupplys } = useSingleCallResult(add, 'totalSupply')
  const totalSupply = useMemo(() => {
    if (!totalSupplys) return
    return toFromBN(totalSupplys[0])
  }, [totalSupplys])
  return totalSupply
}
