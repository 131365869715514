import 'rc-slider/assets/index.css'

import { Trans } from '@lingui/macro'
import { CurrencyAmount } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ReactComponent as LP1 } from 'assets/images/avx/token/lp1.svg'
import LP33 from 'assets/images/avx/token/lp3.png'
import { ReactComponent as LP6 } from 'assets/images/avx/token/lp6.svg'
import Zhiya from 'assets/images/avx/zhiyas.svg'
import Redmm from 'assets/images/lsd/redmm.png'
import StakeBtn from 'assets/images/lsd/stkes.png'
import wsetBtn from 'assets/images/lsd/wzw.png'
import { ButtonPrimary, SmallButtonPrimary } from 'components/Button'
import Column, { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { useDynamicApprove } from 'components/DynamicApprove'
import Modal from 'components/Modal'
import Row, { AutoRow, RowFixed } from 'components/Row'
import { RowBetween } from 'components/Row'
import { useCurrency } from 'hooks/Tokens'
import { useStakeETHPool, useStakePool } from 'hooks/useContract'
import { useInputAmount } from 'hooks/useInputAmount'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useTokenFromActiveNetwork } from 'lib/hooks/useCurrency'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import day from 'moment'
import { default as Slider } from 'rc-slider'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box } from 'rebass'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionInfo, TransactionType } from 'state/transactions/types'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, toFromBN } from 'utils/bn'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { isMobile } from 'utils/userAgent'

import { useAllData, useAvatr, useEsAvatrList, useESAvatrsContract, useESAvatrsTotal, useQueryState } from './hooks'
const TtileBox = styled(Box)`
  /* border: 8px solid #fff; */
  /* height: 92px; */
  height: 60px;
  background: linear-gradient(-39deg, #c0d3ff 0%, #1652f0 100%);
  border-radius: 8px;
  font-family: Roboto-Medium;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    height: auto;
  }
`

const ContainerBox = styled(Box)`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto !important;
  padding: 0 12px;
`
const SizeBox = styled(Box)`
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  span {
    margin-left: 12px;
  }
`

const FarmBox = styled(Box)`
  /* border: 10px solid #fff; */
  margin-top: 32px !important;
  padding: 50px 30px;
  background: #1e2025;
  padding-bottom: 78px;
  border-radius: 8px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding: 18px 12px;
  }
`
const StatuBox = styled(FarmBox)`
  background-size: 100% 100%;
  .border1 {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      height: 60px;
      width: 0px;
      border-left: 1px dashed #b7b7b7;
      top: 15%;
    }
  }
`

const Havster = styled(Box)`
  background: #0653ff;
  border: 1px solid #0653ff;
  border-radius: 4px;
  font-size: 10px;
  font-family: Roboto;
  font-weight: bold;
  color: #ffffff;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`

const FarmTitle = styled(Box)`
  margin-top: 40px;
  font-size: 40px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  line-height: 1;
  padding-bottom: 70px;
`
const CardItem = styled(Box)`
  background: #ffffff;
  border-radius: 16px;
`
const CardTitel = styled(Box)`
  opacity: 1;
  display: flex;
  align-items: center;
  padding-left: 28px;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 500;
  gap: 6px;
  height: 54px;
  padding: 12px;
  background: linear-gradient(-39deg, #c0d3ff 0%, #1652f0 100%);
  border-radius: 16px;
  img {
    width: 30px;
  }
`

const AprBox = styled(Box)`
  border: 1px solid #1652f0;
  border-radius: 4px;
  font-size: 16px;
  font-family: Roboto;
  font-weight: bold;
  color: #0653ff;
  padding: 6px 12px;
  font-weight: 400;
  width: fit-content;
  line-height: 1;
`

const AprSize = styled(Box)`
  font-weight: bold;
  font-size: 22px;
  font-family: Roboto;
  color: #000000;
  margin-top: 2px !important;
`
const StyledSlider = styled(Slider)`
  cursor: pointer;
`
const ApproveBox = styled(Box)`
  background: #ffffff;
  border: 1px solid #7144fb;
  font-size: 12px;
  font-family: 'Pixer';
  font-weight: 600;
  padding: 8px 24px;
  color: #7144fb;
`
const WithdrawBox = styled(Box)`
  border: 1px solid #000000;
  border-radius: 4px;
  font-size: 10px;
  font-family: Roboto;
  font-weight: bold;
  color: #777777;
  padding: 8px;
  cursor: pointer;
  width: 100%;

  /* background: url(${Zhiya}) no-repeat; */
  /* background-size: 100%; */
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
`

const CardBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 48px;
  grid-column-gap: 34px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const StatuCard = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`
const AutoStads = styled(AutoColumn)`
  justify-content: center;
  align-items: center;
  justify-items: center;
`
const StatuAprBox = styled(AprBox)`
  background: #ffffff;
  border-radius: 4px;
  font-size: 20px;
  font-family: Roboto;
  font-weight: bold;
  color: #000000;
  border: none;
  padding: 10px 18px;
`

const VestingBox = styled(Box)`
  font-family: 'Roboto';
`
const VestingBtn = styled(ApproveBox)``

const Redemm = styled(Box)`
  border: 1px solid #969799;
  padding: 8px 12px;
  font-size: 10px;
  font-family: Roboto;
  font-weight: bold;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
`

const LP_LIST = [
  {
    name: 'ETH',
    url: LP6,
    stakeToken: '0x4200000000000000000000000000000000000006',
    isNative: true,
    pool: '0xC310A4beBd3Edcc4BB4c4be30e073D65d80C06b9',
    isLP: false,
    goname: 'ethereum',
    dayOut: 400000,
  },
  {
    name: 'BLSD-ETH',
    url: LP1,
    stakeToken: '0x829cC166e025383A6561B09F248560c0235Cc1E1',
    pool: '0x09f164FCA27aF70bDfef4fc3d24E343309f3c6a8',
    isLP: true,
    symbol: 'LP',
    dayOut: 390000,
  },

  // {
  //   name: 'stETH',
  //   url: LP2,
  //   symbol: 'stETH',
  //   isLP: false,
  //   stakeToken: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
  //   pool: '0x11b69082eF447B6d1fb3d6f93Ee27588338371F4',
  //   goname: 'staked-ether',
  // },
  // {
  //   name: 'rETH',
  //   url: LP5,
  //   isLP: false,
  //   stakeToken: '0xae78736cd615f374d3085123a210448e74fc6393',
  //   pool: '0x49aC7dA8F4D3daBD4418c6467043269F4fCD4f33',
  //   goname: 'rocket-pool-eth',
  // },
  // {
  //   name: 'frxETH',
  //   url: LP4,
  //   stakeToken: '0x5e8422345238f34275888049021821e8e08caa1f',
  //   pool: '0x59f3524E6dEdDC2B3128320ef3eA279EE945B205',
  //   goname: 'frax-ether',
  // },
]
const RemmadeList = () => {
  const List = useEsAvatrList()

  const esContract = useESAvatrsContract()
  const addTransaction = useTransactionAdder()
  const currentTime = Math.floor(new Date().getTime() / 1000)

  const Claim = useCallback(
    async (index: any) => {
      if (!esContract) return

      try {
        const res = await esContract.finalizeRedeem(index)
        addTransaction(res, {
          type: TransactionType.WITHDRAW,
        })
      } catch (error) {
        console.log(error)
      }
    },
    [addTransaction, esContract]
  )

  const Cancel = useCallback(
    async (index: any) => {
      if (!esContract) return
      try {
        const res = await esContract.cancelRedeem(index)
        addTransaction(res, {
          type: TransactionType.WITHDRAW,
        })
      } catch (error) {
        console.log(error)
      }
    },
    [addTransaction, esContract]
  )

  return (
    <>
      {isMobile
        ? List?.map((item: any, index: number) => {
            return (
              <Box mt="40px" key={index + 'RemmadeList'}>
                <Column>
                  <RowBetween>
                    <Box flex="1">
                      <Column gap="xs">
                        <Box flex="1">batch</Box>
                        <Box flex="1">#{index}</Box>
                      </Column>
                    </Box>
                    <Box flex="1">
                      <Column gap="xs">
                        <Box flex="1">BLSD </Box>
                        <Box flex="1">{toFromBN(item.avatrAmount).toFixed(4)}</Box>
                      </Column>
                    </Box>
                  </RowBetween>
                  <RowBetween>
                    <Box flex="1">
                      <Column gap="xs">
                        <Box flex="1">ESBLSD </Box>
                        <Box flex="1">{toFromBN(item.ESAVATRAmount).toFixed(4)}</Box>
                      </Column>
                    </Box>
                    <Box flex="1">
                      <Column gap="xs">
                        <Box flex="1">Claim On</Box>
                        <Box flex="1">{day.unix(item.endTime.toNumber()).format('YYYY/MM/DD h:mm')}</Box>
                      </Column>
                    </Box>
                  </RowBetween>

                  <RowBetween mt="30px">
                    <RowFixed gap="10px">
                      <Box flex="1">
                        <SmallButtonPrimary
                          padding="8px"
                          backgroundColor="#0653ff !important"
                          style={{
                            backgroundColor: '#0653ff !important',
                            border: '0 !important',
                            fontSize: '14px',
                          }}
                          onClick={() => Claim(index)}
                          disabled={!(item.endTime.toNumber() < currentTime)}
                        >
                          Claim
                        </SmallButtonPrimary>
                      </Box>
                      <Box flex="1">
                        <SmallButtonPrimary
                          padding="8px"
                          style={{
                            backgroundColor: '#0653ff !important',
                            border: '0 !important',
                            fontSize: '14px',
                          }}
                          onClick={() => Cancel(index)}
                        >
                          Cancel
                        </SmallButtonPrimary>
                      </Box>
                    </RowFixed>
                  </RowBetween>
                </Column>
              </Box>
            )
          })
        : List?.map((item: any, index: number) => {
            return (
              <Box mt="40px" key={index + 'RemmadeList'}>
                <RowBetween>
                  <Box flex="1">
                    <Column gap="xs">
                      <Box flex="1">batch</Box>
                      <Box flex="1">#{index}</Box>
                    </Column>
                  </Box>
                  <Box flex="1">
                    <Column gap="xs">
                      <Box flex="1">BLSD </Box>
                      <Box flex="1">{toFromBN(item.avatrAmount).toFixed(4)}</Box>
                    </Column>
                  </Box>
                  <Box flex="1">
                    <Column gap="xs">
                      <Box flex="1">ESBLSD </Box>
                      <Box flex="1">{toFromBN(item.ESAVATRAmount).toFixed(4)}</Box>
                    </Column>
                  </Box>
                  <Box flex="1">
                    <Column gap="xs">
                      <Box flex="1">Claim On</Box>
                      <Box flex="1">{day.unix(item.endTime.toNumber()).format('YYYY/MM/DD h:mm')}</Box>
                    </Column>
                  </Box>
                  <Box>
                    <RowFixed gap="10px">
                      <Box flex="1">
                        <SmallButtonPrimary
                          padding="8px"
                          style={{
                            backgroundColor: '#7144fb',
                            border: '0 !important',
                            fontFamily: 'Pixer',
                            fontSize: '14px',
                          }}
                          onClick={() => Claim(index)}
                          disabled={!(item.endTime.toNumber() < currentTime)}
                        >
                          Claim
                        </SmallButtonPrimary>
                      </Box>
                      <Box flex="1">
                        <SmallButtonPrimary
                          padding="8px"
                          backgroundColor="#7144fb"
                          style={{
                            backgroundColor: '#7144fb',
                            border: '0 !important',
                            fontFamily: 'Pixer',
                            fontSize: '14px',
                          }}
                          onClick={() => Cancel(index)}
                        >
                          Cancel
                        </SmallButtonPrimary>
                      </Box>
                    </RowFixed>
                  </Box>
                </RowBetween>
              </Box>
            )
          })}
    </>
  )
}
const Farm = () => {
  const { chainId } = useWeb3React()
  const { avatr, esavatr, esavatrContract } = useAvatr()
  const currency = useCurrency(esavatrContract?.address)
  const addTransaction = useTransactionAdder()
  const { lpArr, tvl, avatPrice } = useAllData(LP_LIST)
  const esAVatr = useESAvatrsTotal()
  useEffect(() => {
    console.log('lpArr', lpArr, tvl, avatPrice)
  }, [avatPrice, lpArr, tvl])
  const {
    inputAmount: inputAmountDeposit,
    isInputValid: isInputValidDeposit,
    inputError: inputErrorDeposit,
    handleUserInput: handleUserInputDeposit,
    handleResetInput: handleResetInputDeposit,
    handleMax: handleMaxDeposit,
  } = useInputAmount(currency)

  const usdTotal = useMemo(() => {
    if (!esAVatr || !avatPrice) return 0
    const add = esAVatr.times(avatPrice).toFixed(2)
    return add === 'NaN' ? 0 : add
  }, [avatPrice, esAVatr])
  const [sweepAmount, setSweepAmount] = useState<number>(3)

  const rates = useMemo(() => {
    if (sweepAmount == 3) return 50
    return (sweepAmount / 14) * 50 + 50
  }, [sweepAmount])

  const amountsa = useMemo(() => {
    if (!esavatr) return
    if (esavatr.gt(0)) {
      return esavatr.times(rates).div(100).toFixed(4)
    }
    return '0'
  }, [esavatr, rates])
  const handleDeposit = useCallback(async () => {
    if (!esavatrContract || !esavatr) {
      return
    }
    setLoadingDeposit(true)

    const data = sweepAmount * 3600 * 24
    const add = esavatr.times(BN(10).pow(18)).toFixed(0)
    try {
      const res = await esavatrContract.redeem(add, data)
      addTransaction(res, {
        type: TransactionType.DEPOSIT,
      } as TransactionInfo)
      handleResetInputDeposit()
      setDepositOpen(false)
      setSweepAmount(3)
    } catch (err) {
      console.log('[err]:', err)
    } finally {
      setLoadingDeposit(false)
    }
  }, [addTransaction, esavatr, esavatrContract, handleResetInputDeposit, sweepAmount])
  const [depositOpen, setDepositOpen] = useState(false)
  const [loadingDeposit, setLoadingDeposit] = useState(false)
  const [isReadyDeposit, reasonDeposit] = useMemo(() => {
    const _reason: React.ReactNode | undefined = inputErrorDeposit
    const _isReady = isInputValidDeposit && !loadingDeposit

    return [_isReady, _reason]
  }, [inputErrorDeposit, isInputValidDeposit, loadingDeposit])

  const handleSliderChange = (value: number | number[]) => {
    if (typeof value === 'number') {
      setSweepAmount(value)
    }
  }

  return (
    <ContainerBox pt="32px">
      <TtileBox>
        <Box display="flex" alignItems="center" height="100%">
          <AutoRow justify="center" height="100%" gap="8px">
            <SizeBox>
              TVL <span>${tvl} </span>
            </SizeBox>
            <SizeBox>
              BLSD <span>${avatPrice ?? 0} </span>
            </SizeBox>
          </AutoRow>
        </Box>
      </TtileBox>
      <FarmBox>
        <Box>
          <Column>
            <FarmTitle>Farm</FarmTitle>
          </Column>
        </Box>
        <CardBox>
          {lpArr?.map((item: any, index: any) => {
            return <CardItems CardItemsData={item} key={index + '' + item.pool} />
          })}
        </CardBox>
      </FarmBox>
      <StatuBox>
        <FarmTitle pb="20px" pt="38px">
          Status
        </FarmTitle>
        <StatuCard>
          <Box>
            <AutoStads gap="23px">
              <StatuAprBox>TVL</StatuAprBox>
              <SizeBox>
                <span style={{ fontFamily: 'sans-serif' }}>${usdTotal ?? 0} </span>
              </SizeBox>
            </AutoStads>
          </Box>

          <Box>
            <AutoStads gap="12px">
              <StatuAprBox>BLSD</StatuAprBox>
              <SizeBox>
                PRICE <span style={{ fontFamily: 'sans-serif' }}>${avatPrice ?? 0}</span>
              </SizeBox>
            </AutoStads>
          </Box>
        </StatuCard>
      </StatuBox>
      <StatuBox>
        <FarmTitle pb="38px" pt="38px">
          esAVATR Vesting
        </FarmTitle>
        <Row justify="center" gap="20px" alignContent="center">
          <VestingBox>
            Balance: $BLSD:
            <span style={{ fontFamily: 'sans-serif' }}>{avatr?.toFixed(4)}</span> {'  '}$esBLSD:
            <span style={{ fontFamily: 'sans-serif' }}>{esavatr?.toFixed(4)}</span>
          </VestingBox>
          <Redemm onClick={() => setDepositOpen(true)}>
            <img src={Redmm} />
            Redeem
          </Redemm>
        </Row>
        <RemmadeList />
      </StatuBox>
      <Modal
        key="curdsa"
        isOpen={depositOpen}
        onDismiss={() => {
          setDepositOpen(false)
        }}
        maxHeight={90}
      >
        <Box sx={{ width: '420px', padding: [24], background: '' }}>
          <AutoColumn gap="md">
            <ThemedText.DeprecatedBlack fontSize={23}>Choose Vesting Time</ThemedText.DeprecatedBlack>
          </AutoColumn>
          <Box p="20px 0">
            <StyledSlider
              defaultValue={sweepAmount}
              min={3}
              max={14}
              value={sweepAmount}
              step={1}
              trackStyle={{
                top: '3px',
                height: '8px',
                background: `radial-gradient(101.8% 4091.31% at 0% 0%, #4673FA 0%, #9646FA 100%)`,
              }}
              handleStyle={{
                top: '3px',
                width: '12px',
                height: '20px',
                backgroundColor: `#4673FA`, //This is a custom color to align with the gradient on sweep - we may want to systematize it eventually
                borderRadius: '4px',
                border: 'none',
                opacity: '1',
                // boxShadow: `${theme.shallowShadow.slice(0, -1)}`,
              }}
              railStyle={{
                top: '3px',
                height: '8px',
                // backgroundColor: `${'#fff'}`,
              }}
              onChange={handleSliderChange}
            />
          </Box>
          <Box>
            <ThemedText.DeprecatedBlack fontSize={14}>{sweepAmount} Days</ThemedText.DeprecatedBlack>
          </Box>

          <Box p="20px 0">
            <ThemedText.DeprecatedBlack fontSize={18}>You get</ThemedText.DeprecatedBlack>
          </Box>
          <Box>
            <ThemedText.DeprecatedBlack fontSize={14}>
              <span style={{ fontFamily: 'sans-serif' }}>{amountsa} </span> $AVATR
              <span style={{ fontFamily: 'sans-serif' }}>&nbsp;{rates.toFixed(2)}%</span>
            </ThemedText.DeprecatedBlack>
          </Box>

          <Row justify="center" sx={{ mt: [14] }}>
            <ButtonPrimary
              sx={{ border: '0 !important', ml: ['8px'], color: '' }}
              disabled={!esavatr?.gt(0)}
              onClick={handleDeposit}
            >
              <Trans>Redeem</Trans>
            </ButtonPrimary>
          </Row>
        </Box>
      </Modal>
    </ContainerBox>
  )
}

const CardItems = ({ CardItemsData }: any) => {
  const { tokenBalance, earned, contract, totalSupply } = useQueryState(CardItemsData)
  const { account, chainId } = useWeb3React()
  const ESAvatrsContract = useESAvatrsContract()
  const [depositOpen, setDepositOpen] = useState(false)
  const [withdrawOpen, setWithdrawOpen] = useState(false)
  const native = useNativeCurrency()
  const tokens = useTokenFromActiveNetwork(CardItemsData.stakeToken)
  const inputCurrency = CardItemsData?.isNative ? native : tokens
  const inputCurrency2 = useCurrency(CardItemsData.pool)
  const esavtrs = useCurrency(ESAvatrsContract?.address)

  const [loadingDeposit, setLoadingDeposit] = useState(false)
  const [loadingWithdraw, setLoadingWithdraw] = useState(false)
  const addTransaction = useTransactionAdder()
  const poolsETH = useStakeETHPool(CardItemsData.pool)
  const pools = useStakePool(CardItemsData.pool)

  const {
    inputAmount: inputAmountDeposit,
    isInputValid: isInputValidDeposit,
    inputError: inputErrorDeposit,
    handleUserInput: handleUserInputDeposit,
    handleResetInput: handleResetInputDeposit,
    handleMax: handleMaxDeposit,
  } = useInputAmount(inputCurrency)
  const {
    inputAmount: inputAmountWithdraw,
    isInputValid: isInputValidWithdraw,
    inputError: inputErrorWithdraw,
    handleUserInput: handleUserInputWithdraw,
    handleResetInput: handleResetInputWithdraw,
    handleMax: handleMaxWithdraw,
  } = useInputAmount(inputCurrency2)

  const { DynamicApprove, isApproved, approveError } = useDynamicApprove(
    [inputAmountDeposit.amount],
    CardItemsData.pool
  )

  const [isReadyWithdraw, reasonWithdraw] = useMemo(() => {
    const isReady = isInputValidWithdraw && !loadingWithdraw

    const reason = inputErrorWithdraw

    return [isReady, reason]
  }, [inputErrorWithdraw, isInputValidWithdraw, loadingWithdraw])
  const [isReadyDeposit, reasonDeposit] = useMemo(() => {
    const _reason: React.ReactNode | undefined = inputErrorDeposit || approveError
    const _isReady = isInputValidDeposit && isApproved && !loadingDeposit

    return [_isReady, _reason]
  }, [inputErrorDeposit, approveError, isInputValidDeposit, isApproved, loadingDeposit])
  const { result: stakeBalances } = useSingleCallResult(pools, 'balanceOf', [account ?? undefined])
  const depositedCurrencyAmount = useMemo(() => {
    if (!inputCurrency || !stakeBalances) return
    return CurrencyAmount.fromRawAmount(inputCurrency, stakeBalances[0])
  }, [inputCurrency, stakeBalances])

  const handleWithdraw = useCallback(async () => {
    if (!pools || !account || !poolsETH) {
      return
    }
    setLoadingWithdraw(true)
    const _amount_in = inputAmountWithdraw.amount?.quotient?.toString()
    if (!_amount_in) {
      return
    }
    try {
      const res = await pools.withdraw(_amount_in)
      addTransaction(res, {
        type: TransactionType.WITHDRAW,
      })
      handleResetInputWithdraw()
    } catch (err) {
      console.log('[err]:', err)
    } finally {
      setLoadingWithdraw(false)
      setWithdrawOpen(false)
    }
  }, [account, addTransaction, handleResetInputWithdraw, inputAmountWithdraw.amount?.quotient, pools, poolsETH])

  const handleDeposit = useCallback(async () => {
    if (!pools || !account || !poolsETH) {
      return
    }
    const _amount_in = inputAmountDeposit.amount?.quotient?.toString()
    if (!_amount_in) {
      return
    }
    setLoadingDeposit(true)
    try {
      const contract = CardItemsData.isNative ? poolsETH : pools
      const datas = { value: CardItemsData.isNative ? _amount_in : '0' }
      const res = await contract.stake(_amount_in, datas)
      addTransaction(res, {
        type: TransactionType.DEPOSIT,
        unwrapped: false,
        currencyAmountRaw: _amount_in,
        chainId,
      } as TransactionInfo)
      handleResetInputDeposit()
    } catch (err) {
      console.log('[err]:', err)
    } finally {
      setLoadingDeposit(false)
      setDepositOpen(false)
    }
  }, [
    CardItemsData.isNative,
    account,
    addTransaction,
    chainId,
    handleResetInputDeposit,
    inputAmountDeposit.amount?.quotient,
    pools,
    poolsETH,
  ])

  const handleWithdrawAll = useCallback(async () => {
    if (!pools || !account || !poolsETH) {
      return
    }

    try {
      const res = await pools.exit()
      addTransaction(res, {
        type: TransactionType.WITHDRAW,
      })
    } catch (err) {
      console.log('[err]:', err)
    }
  }, [account, addTransaction, pools, poolsETH])

  const Reward = useCallback(async () => {
    if (!pools) return
    try {
      // if (farcoryApprove) {
      //   await approve0()
      // }
      const res = await pools.getReward()
      addTransaction(res, {
        type: TransactionType.WITHDRAW,
      })
    } catch (error) {
      console.log('error', error)
    }
  }, [addTransaction, pools])
  const theme = useTheme()
  return (
    <Box key={'CardItems' + CardItemsData.name}>
      <CardItem>
        <CardTitel>
          {CardItemsData.name == 'AGI-ETH LP' ? <img src={LP33} width={50} alt="" /> : <CardItemsData.url />}

          {CardItemsData.name}
        </CardTitel>
        <Box pt="18px">
          <Row gap="50px" padding="0  0 0 33px">
            <Column>
              <AprBox>APR</AprBox>
              <AprSize>{CardItemsData.apr ?? 0}%</AprSize>
            </Column>
            <Column>
              <AprBox>TVL</AprBox>
              <AprSize>${CardItemsData?.tvl1 ?? 0}</AprSize>
            </Column>
          </Row>
        </Box>

        <Box pl="23px" pr="23px" mt="25px">
          <Column style={{ borderBottom: '1px dashed #B7B7B7' }}>
            <RowBetween gap="2px">
              <Box pl="10px" pb="6px">
                <Box color="#777777" fontSize="12px" fontWeight="bold" style={{ fontFamily: 'Roboto' }}>
                  esBLSD Earned
                </Box>
                <Box color="#000000" fontSize="10px" fontWeight="bold" style={{ fontFamily: 'Roboto' }}>
                  {earned?.toFixed(4) ?? 0} $esBLSD
                </Box>
              </Box>
              {earned?.gt(0) && (
                <Havster onClick={Reward}>
                  <img src={StakeBtn} alt="" /> Havset
                </Havster>
              )}
            </RowBetween>
          </Column>
        </Box>
        <Box p="0 33px" mt="10px">
          <RowBetween alignItems="center">
            <Column>
              <Box pb="10px">
                <Box color="#777777" fontSize="10px" fontWeight="700" style={{ fontFamily: 'Roboto' }}>
                  {CardItemsData?.name} Staked
                </Box>
                <Box color="#777777" fontSize="10px" fontWeight="700" style={{ fontFamily: 'Roboto' }}>
                  {depositedCurrencyAmount?.toExact() ?? 0}
                </Box>
              </Box>
            </Column>
            <Box onClick={() => setDepositOpen(true)} style={{ cursor: 'pointer' }}>
              <Havster>
                <img src={StakeBtn} alt="" /> Stake
              </Havster>
            </Box>
          </RowBetween>
        </Box>
        <Box p="0 33px" mt="10px" pb="22px">
          <RowBetween gap="23px" style={{ cursor: 'pointer' }}>
            <WithdrawBox onClick={() => setWithdrawOpen(true)}>
              <img src={wsetBtn} alt="" /> Withdraw
            </WithdrawBox>
            <WithdrawBox onClick={() => handleWithdrawAll()}>
              <img src={wsetBtn} alt="" /> Withdraw ALL
            </WithdrawBox>
          </RowBetween>
        </Box>
      </CardItem>

      <Modal
        isOpen={depositOpen}
        onDismiss={() => {
          setDepositOpen(false)
        }}
        maxHeight={90}
      >
        <Box sx={{ width: '420px', padding: [24], background: '' }}>
          <AutoColumn gap="md">
            <ThemedText.DeprecatedBlack fontSize={23}>Deposit</ThemedText.DeprecatedBlack>
            <CurrencyInputPanel
              value={inputAmountDeposit.value}
              onUserInput={(_value) => {
                handleUserInputDeposit(_value)
              }}
              symbol={CardItemsData.symbol}
              showMaxButton={true}
              onMax={handleMaxDeposit}
              currency={inputCurrency}
              id="amount-input"
            />
          </AutoColumn>

          <Row justify="center" sx={{ mt: [14] }}>
            <DynamicApprove />
            <ButtonPrimary
              sx={{ border: '0 !important', ml: ['8px'], color: '' }}
              disabled={!isReadyDeposit}
              onClick={handleDeposit}
            >
              <Trans>Deposit</Trans>
            </ButtonPrimary>
          </Row>
        </Box>
      </Modal>
      <Modal
        isOpen={withdrawOpen}
        onDismiss={() => {
          setWithdrawOpen(false)
        }}
        maxHeight={90}
      >
        <Box sx={{ width: '100%', padding: [24], background: '' }}>
          <AutoColumn gap="md">
            <ThemedText.BodyPrimary fontSize={23}>
              <Trans>Withdraw</Trans>
            </ThemedText.BodyPrimary>
            <CurrencyInputPanel
              value={inputAmountWithdraw.value}
              onUserInput={(_value) => {
                handleUserInputWithdraw(_value)
              }}
              showMaxButton={true}
              onMax={() => {
                handleMaxWithdraw(depositedCurrencyAmount)
              }}
              renderBalance={() => (
                <ThemedText.BodyPrimary
                  variant="gray"
                  fontWeight={400}
                  fontSize={14}
                  className="text-show-significant"
                  style={{ display: 'inline' }}
                >
                  <Trans>Balance</Trans>: {formatCurrencyAmount(depositedCurrencyAmount, 6)}
                  LP
                </ThemedText.BodyPrimary>
              )}
              currency={inputCurrency}
              id="amount-input"
            />
          </AutoColumn>

          <Row justify="center" sx={{ mt: [14] }}>
            <ButtonPrimary
              sx={{ border: '0 !important', ml: ['8px'] }}
              disabled={!isReadyWithdraw}
              onClick={handleWithdraw}
            >
              <ThemedText.BodyPrimary fontSize={18} fontWeight="bold">
                <Trans>Withdraw</Trans>
              </ThemedText.BodyPrimary>
            </ButtonPrimary>
          </Row>
        </Box>
      </Modal>
    </Box>
  )
}

export default Farm
