import { Trans } from '@lingui/macro'
import BG2 from 'assets/images/avx/discord.png'
import BG1 from 'assets/images/avx/twitter.png'
import LOGO from 'assets/images/baselsd.png'
import Web3Status from 'components/Web3Status'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { bodySmall } from 'nft/css/common.css'
import { useProfilePageState } from 'nft/hooks'
import { ReactNode } from 'react'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import Blur from './Blur'
import { ChainSelector } from './ChainSelector'
import * as styless from './MenuDropdown.css'
import * as styles from './style.css'

const Nav = styled.nav`
  padding: 20px 12px;
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;
`
const Dds = styled(Box)<{ isActive?: boolean }>`
  font-size: 12px;
  font-family: Roboto;
  font-weight: 500;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? '#0653FF' : '#ffffff')};
`
const SecondaryLinkedText = ({
  href,
  onClick,
  children,
  width,
}: {
  href?: string
  onClick?: () => void
  children: ReactNode
  width?: any
}) => {
  return (
    <Box
      as={href ? 'a' : 'div'}
      style={{ width, padding: '8px 14px', margin: '4px 0' }}
      href={href ?? undefined}
      target={href ? '_blank' : undefined}
      rel={href ? 'noopener noreferrer' : undefined}
      onClick={onClick}
      className={`${styless.SecondaryText} ${bodySmall}`}
      fontWeight="medium"
      fontSize="16"
    >
      <Dds>{children}</Dds>
    </Box>
  )
}

interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
  tagert?: boolean
}

const MenuItem = ({ href, dataTestId, id, isActive, children, tagert }: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: 'none' }}
      data-testid={dataTestId}
      target={tagert ? '_blank' : ''}
    >
      <Dds isActive={isActive}>{children}</Dds>
    </NavLink>
  )
}

export const PageTabs = () => {
  const { pathname } = useLocation()

  return (
    <>
      <MenuItem href="/farm" isActive={pathname.startsWith('/farm')}>
        <Trans>Farm</Trans>
      </MenuItem>

      <Box display={{ sm: 'flex', lg: 'flex', xxl: 'flex' }} width="full">
        <SecondaryLinkedText href="" width="max-content">
          aUSD Trading
        </SecondaryLinkedText>
      </Box>
      <Box display={{ sm: 'flex', lg: 'flex', xxl: 'flex' }} width="full">
        <SecondaryLinkedText href="" width="max-content">
          Docs
        </SecondaryLinkedText>
      </Box>
      <Box display={{ sm: 'flex', lg: 'flex', xxl: 'flex' }} width="full">
        <SecondaryLinkedText href="https://app.uniswap.org/#/swap" width="max-content">
          Buy $BLSD
        </SecondaryLinkedText>
      </Box>
      <Box display={{ sm: 'flex', lg: 'flex', xxl: 'flex' }} width="full">
        <SecondaryLinkedText href="https://app.uniswap.org/#/add/v2/ETH" width="max-content">
          Triceratops Rewards
        </SecondaryLinkedText>
      </Box>
      {/* {micrositeEnabled && (
        <Box display={{ sm: 'none', xxxl: 'flex' }}>
          <MenuItem href="/wallet" isActive={pathname.startsWith('/wallet')}>
            <Trans>Wallet</Trans>
            <NewBadge />
          </MenuItem>
        </Box>
      )} */}
      {/* <Box marginY={{ sm: '4', md: 'unset' }}>
        <MenuDropdown />
      </Box> */}
    </>
  )
}

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage()
  const sellPageState = useProfilePageState((state) => state.state)
  const navigate = useNavigate()

  return (
    <>
      {blur && <Blur />}
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap">
          <Box className={styles.leftSideContainer}>
            <Box className={styles.logoContainer}>
              <img src={LOGO} className={styles.logo} data-testid="uniswap-logo" />
            </Box>

            <Row display={{ sm: 'none', lg: 'flex' }}>
              <PageTabs />
            </Row>
          </Box>

          <Box className={styles.rightSideContainer}>
            <Row gap="12">
              {/* <Box position="relative" display={{ sm: 'flex', navSearchInputVisible: 'none' }}>
                <SearchBar />
              </Box> */}

              <Box display={{ sm: 'flex', lg: 'flex' }}>
                <Row gap="8">
                  <img src={BG1} alt="" style={{ cursor: 'pointer' }} onClick={() => window.open('')} />
                  <img src={BG2} alt="" style={{ cursor: 'pointer' }} onClick={() => window.open('')} />
                </Row>
                <ChainSelector />
              </Box>

              <Web3Status />
            </Row>
          </Box>
        </Box>
      </Nav>
    </>
  )
}

export default Navbar
